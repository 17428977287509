<template>
  <Voorwaarden />
</template>

<script>
import Voorwaarden from "@/components/Voorwaarden";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Voorwaarden,
  },
  metaInfo: {
    title: "Kantoorflex algemene voorwaarden | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
     meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Algemenevoorwaarden met kantoorflex Nederland.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>